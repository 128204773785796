/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, flow, makeObservable, observable, reaction } from "mobx";

import { CachedFilter, FilterTypes, type Filter } from "@config/filters.const";
import { logger as baseLogger } from "@core/logger";
import { sleep } from "@core/utils";

import type { RootStoreType } from "./root_store";

const logger = baseLogger.getSubLogger({ name: "filterStore" });

export const LOCAL_STORAGE_FILTERS_KEY = "filters";
export const LOCAL_STORAGE_PREFERENCES_KEY = "optAndScenarioPreferences";
const LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY = "globalPreferences";

class UserPreferences {
  parent: RootStoreType;
  _enabled = true;
  _update_pending = false;
  fav_subs = new Map();
  networkId?: string;
  userId?: string;
  internalFeaturesEnabled = false;

  constructor(parent: RootStoreType) {
    makeObservable(this, {
      cachedFilters: computed,
      isInternalFeaturesEnabled: computed,
      parent: true,
      _enabled: true,
      _update_pending: true,
      getFilterStorage: true,
      clearFilterStorage: true,
      upsertFilterStorage: action.bound,
      filtersInLocalStorage: computed,
      hasLocalFiltersCache: computed,
      networkId: observable,
      userId: observable,
      upsertSimResultCollapsible: true,
      disableSync: true,
      toggleFavSub: true,
      clearFavorites: true,
      toJson: true,
      fav_subs: observable,
      resetPref: action.bound,
      fromJson: action.bound,
      getSimulationResultInLocalStorage: true,
      getSimResultCollapsibleTab: true,
      setUserId: action.bound,
      updateInternalFeaturesEnabled: action.bound,
      internalFeaturesEnabled: observable,
    });
    this.parent = parent;
    this.disableSync = this.disableSync.bind(this);

    // Read from localStorage
    this.internalFeaturesEnabled = this.isInternalFeaturesEnabled;

    // if cachedFilters computed updates, we make sure to call the upsertFilterStorage
    // to update the filters in local storage
    reaction(
      () => this.cachedFilters,
      (newCachedFilters) => {
        this.upsertFilterStorage(newCachedFilters);
      }
    );
  }

  get filtersInLocalStorage(): { [networkId: string]: { [userId: string]: any } } | null {
    const storedFilters = localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY);
    return storedFilters !== null ? JSON.parse(storedFilters) : null;
  }

  get hasLocalFiltersCache(): boolean {
    return (
      typeof this.networkId === "string" &&
      typeof this.userId === "string" &&
      Boolean(this.filtersInLocalStorage?.[this.networkId]?.[this.userId])
    );
  }

  setUserId(userId: string) {
    this.userId = userId;
    this.networkId = this.parent.networks.current_network?.uid;
  }

  disableSync(): void {
    this._enabled = false;
  }

  toggleFavSub = flow(function* (
    this: UserPreferences,
    network_id: string,
    sub_id: string
  ): Generator<any, void, unknown> {
    let cnet = null;
    if (this.fav_subs.has(network_id)) {
      cnet = this.fav_subs.get(network_id);
    } else {
      cnet = new Set();
      this.fav_subs.set(network_id, cnet);
    }
    if (cnet.has(sub_id)) {
      cnet.delete(sub_id);
    } else {
      cnet.add(sub_id);
    }

    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
    yield this.parent.filters.updateFavouritesFilter();
  });

  resetPref() {
    this.fav_subs.clear();
  }

  clearFavorites = flow(function* (this: UserPreferences, network_id: string) {
    this.fav_subs.set(network_id, new Set<string>());
    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
  });

  fromJson(jsn: { fav_subs: { [networkId: string]: unknown[] } }) {
    if (Object.hasOwn(jsn, "fav_subs")) {
      const updatedFavSubs = new Map<string, Set<string>>(
        Object.entries(jsn.fav_subs).map(([networkId, subs]) => [
          networkId,
          new Set<string>(subs as string[]),
        ])
      );
      this.fav_subs = updatedFavSubs;
    }
  }

  toJson() {
    const resjson: { fav_subs: { [networkId: string]: string[] } } = { fav_subs: {} };
    for (const [networkId, favSet] of this.fav_subs.entries()) {
      resjson.fav_subs[networkId] = Array.from(favSet);
    }
    return resjson;
  }

  get isInternalFeaturesEnabled(): boolean {
    // Read from localStorage
    const item = localStorage.getItem(LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY);
    const globalPreferences: {
      internalFeaturesEnabled?: boolean;
    } = item ? JSON.parse(item) : {};

    return globalPreferences.internalFeaturesEnabled ?? false;
  }

  updateInternalFeaturesEnabled(enabled: boolean) {
    // Write to localStorage
    const globalPreferences = {
      internalFeaturesEnabled: enabled,
    };
    localStorage.setItem(LOCAL_STORAGE_GLOBAL_PREFERENCES_KEY, JSON.stringify(globalPreferences));
    this.internalFeaturesEnabled = enabled;
  }

  upsertSimResultCollapsible(headingTitle: string | number): void {
    if (!this.networkId || !this.userId) return;
    logger.debug(`Upserting ${headingTitle} in simResultCollapsible in localStorage`);
    const simResultCollapsible = this.getSimulationResultInLocalStorage() || {
      simResultCollapse: {},
    };
    const userCollapsible =
      simResultCollapsible.simResultCollapse?.[this.networkId]?.[this.userId] || {};

    const collapsibleByTitle =
      userCollapsible[headingTitle] !== undefined ? !userCollapsible[headingTitle] : true; // Default to true if undefined

    userCollapsible[headingTitle] = collapsibleByTitle;
    simResultCollapsible.simResultCollapse = {
      ...simResultCollapsible.simResultCollapse,
      [this.networkId]: {
        ...simResultCollapsible.simResultCollapse?.[this.networkId],
        [this.userId]: userCollapsible,
      },
    };

    localStorage.setItem(LOCAL_STORAGE_PREFERENCES_KEY, JSON.stringify(simResultCollapsible));
  }

  getSimulationResultInLocalStorage(): {
    simResultCollapse?: Record<string, Record<string, Record<string | number, boolean>>>;
  } | null {
    const item = localStorage.getItem(LOCAL_STORAGE_PREFERENCES_KEY);
    return item ? JSON.parse(item) : null;
  }

  getSimResultCollapsibleTab(headingTitle: string | number): boolean {
    if (!this.networkId || !this.userId) return true;
    const { simResultCollapse } = this.getSimulationResultInLocalStorage() || {};
    return (
      simResultCollapse?.[this.networkId]?.[this.userId]?.[headingTitle] ??
      // Default to true
      true
    );
  }

  getFilterStorage(): CachedFilter[] | undefined {
    if (!this.networkId || !this.userId || !this.filtersInLocalStorage) return;
    // We updated the way that filters are stored. We dont care about migrating, so we try to parse to CachedFilter[]
    // Otherwise we clear the storage
    try {
      // Best way is to check for state on a filter, if it has state, it is an old filter
      if (
        this.filtersInLocalStorage[this.networkId][this.userId].some((filter: any) => filter.state)
      ) {
        this.clearFilterStorage();
        return;
      }
      return this.filtersInLocalStorage[this.networkId][this.userId];
    } catch (e) {
      this.clearFilterStorage();
      return;
    }
  }
  clearFilterStorage() {
    localStorage.removeItem(LOCAL_STORAGE_FILTERS_KEY);
  }

  get cachedFilters(): CachedFilter[] | undefined {
    // We explicitly read the filters from the store so that it can be reactive, instead of passing it as an argument
    // which would make it non-reactive
    if (!this.userId || !this.networkId || !this.parent.filters.isReady) return;
    // Map over filters and merge with cached filters
    const newCachedFilters = this.parent.filters.filters
      // Dont store non active filters
      .filter((filter: Filter) => filter.state.isActive)
      .map((filter: Filter) => {
        const reducedFilter: CachedFilter = {
          param: filter.param,
          excludeMatches: filter.state.excludeMatches,
          includeMissing: filter.state.include,
        };

        switch (filter.type) {
          case FilterTypes.CATEGORICAL:
            // Remove duplicates
            reducedFilter.selected = filter.state.selected;
            break;
          case FilterTypes.HISTOGRAM:
            reducedFilter.min = filter.state.min;
            reducedFilter.max = filter.state.max;
            break;
          default:
            logger.error(`Filter type ${filter.type} not supported`);
            break;
        }

        return reducedFilter;
      });

    return newCachedFilters;
  }

  /**
   * Upserts filters in local storage.
   */
  upsertFilterStorage(newCachedFilters: CachedFilter[] | undefined): void {
    if (!this.parent.filters.isReady) {
      logger.debug("Filters are not ready, skipping upsert");
      return;
    }
    logger.debug("Upserting filters in localStorage...");

    if (!this.networkId || !this.userId || !newCachedFilters || !newCachedFilters.length) {
      logger.debug("No filters to upsert");
      return;
    }

    // We only update for the network that we are currently on
    const updatedNetworkFilters = {
      // Keep the rest of the networks
      ...this.filtersInLocalStorage,
      [this.networkId]: {
        // Keep the rest of the users
        ...this.filtersInLocalStorage?.[this.networkId],
        // Set the new filters for the current user
        [this.userId]: newCachedFilters,
      },
    };

    // Update filters in local storage
    localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(updatedNetworkFilters));
  }
}

export default UserPreferences;
