import  { useCallback } from "react";
import { Box } from "@mui/material";

import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { useLocalStorage } from "@hooks";

import UtfFileReader from "../../../../../../libs/components/src/ui/inputs/UtfFileReader/UtfFileReader";

const WeatherCard = ({
  data,
  onChange,
}: {
  data: {
    values: number[];
    name: string;
  };
  onChange: (values: number[], name: string) => void;
}) => {
  const collectionId = alternativeCostStore.get.selectedCollection()?.id;
  const configId = alternativeCostStore.get.selectedConfiguration()?.id;
  const storageKey = `alt-cost/meta-data/${collectionId}/${configId}/weather`;

  const [storedData, setStoredData] = useLocalStorage(storageKey, {
    name: data.name || "",
    values: data.values || [],
  });

  const handleDataRead = useCallback(
    (values: number[], name: string) => {
      const newData = { name, values };
      setStoredData(newData);
      onChange(values, name);
    },
    [setStoredData, onChange]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
      <UtfFileReader
        key={storedData.name}
        onDataRead={handleDataRead}
        initialFileName={storedData.name}
        initialData={storedData.values}
        accept=".csv"
        chartTitle="Outdoor Temperature"
      />
    </Box>
  );
};

export default WeatherCard;