import { FC } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";
import { faVial } from "@fortawesome/pro-regular-svg-icons";
import { faTimer } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { logger } from "@core/logger";
import { useFeatureFlagEnabled, useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

export type SideBarRoute = {
  route?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon?: React.ComponentType<any>;
  text: string;
  children?: SideBarRoute[];
  cb?: () => void;
  access?: string[];
  isRoot?: boolean;
  featureFlag?: string;
};

type SideBarItemProps = {
  routes: SideBarRoute;
};

const renderFeatureIcon = (isFeatureFlagOn: boolean, featureFlag: string | undefined) => {
  if (isFeatureFlagOn) {
    return (
      <Tooltip title="Experimental Feature" placement="right">
        <FontAwesomeIcon icon={faVial} fontSize="large" />
      </Tooltip>
    );
  }

  if (featureFlag) {
    return (
      <Tooltip title="Coming Soon!" placement="right">
        <FontAwesomeIcon icon={faTimer} fontSize="large" />
      </Tooltip>
    );
  }

  return null;
};

function LockIcon() {
  return <FontAwesomeSvgIcon icon={faLockKeyhole} sx={{ fontSize: 13 }} />;
}

export const SideBarItem: React.FC<SideBarItemProps> = ({ routes }) => {
  const { route = "", Icon, text, children = [], cb = null, access, isRoot, featureFlag } = routes;
  const { routerStore, networks, ui } = useInject("routerStore", "networks", "ui");
  const {
    routerState: { routeName },
  } = routerStore;

  const isFeatureFlagOn = useFeatureFlagEnabled(featureFlag ?? "");

  const haveRoute = route.length > 0;
  const haveChildren = children.length > 0;
  const firstChildWithRoute = children.find((child) => typeof child.route === "string");
  const haveAccess = networks.haveAccess(access);
  const isCurrentRoute = route === routeName;
  // Contains the route in the children
  const childrenHaveRoute = !!children.find((child) => routeName.includes(child.route));
  const isSelected = isCurrentRoute || childrenHaveRoute;
  const id = haveRoute ? route : children[0]?.route;
  const open = haveChildren && childrenHaveRoute;

  const navigateToRoute = () => {
    if (!haveAccess) {
      return;
    }

    if (featureFlag && !isFeatureFlagOn) {
      return;
    }

    if (cb) {
      cb();
    }

    (async () => {
      if (haveChildren && firstChildWithRoute) {
        return runInAction(() => routerStore.goTo(String(firstChildWithRoute.route)));
      }

      if (route.length > 0) {
        ui.closeDrawerOnSmallScreen();
        return runInAction(() => routerStore.goTo(route));
      }

      logger.error(`No "route" was found for`, route);
      return false;
    })();
  };

  return (
    <Box sx={{ mb: isRoot ? 1.5 : 0 }}>
      <ListItemButton
        sx={{
          zIndex: 1,
          boxShadow: haveChildren && open ? "0px 2px 4px rgba(0, 0, 0, 0.12)" : "none",
          backgroundColor: isSelected ? "grey.300" : "grey.200",
          height: 48,
          pl: 3,
        }}
        disabled={!networks.ready}
        selected={isSelected}
        onClick={navigateToRoute}
        data-testid={children.length ? `sidebar-item-${id}-parent` : `sidebar-item-${id}`}
      >
        <ListItemIcon sx={{ pr: 4 }}>
          <Box sx={{ "& svg": { fontSize: 24, color: "primary.main" } }}>
            {Icon ? <Icon /> : <> </>}
          </Box>
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            color: isSelected ? "secondary" : "primary",
            variant: "button",
          }}
        />
        {haveChildren && haveAccess && (
          <div>
            {open ? (
              <ExpandLessIcon data-testid="lessIcon" color="primary" />
            ) : (
              <ExpandMoreIcon data-testid="moreIcon" color="primary" />
            )}
          </div>
        )}
        {!haveAccess && (
          <div data-testid="sidebar-lock-icon">
            <LockIcon />
          </div>
        )}
        <Box sx={{ "& svg": { color: "secondary.main" } }}>
          {renderFeatureIcon(isFeatureFlagOn, featureFlag)}
        </Box>
      </ListItemButton>
      
      {/* Nested Items */}
      {haveChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              backgroundColor: isSelected ? "grey.200" : "grey.100",
            }}
          >
            {children.map((child) => {
              if (child.route) {
                return <SideBarNestedItem key={child.route} routes={child} />;
              } else {
                return (
                  <h1 key={child.text} style={{ display: "none" }}>
                    {child.text}
                  </h1>
                );
              }
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SideBarNestedItem = observer<FC<SideBarItemProps>>(SideBarItem);

SideBarNestedItem.displayName = "SideBarNestedItem";
