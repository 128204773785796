/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  type MRT_ColumnDef,
  type MRT_ColumnOrderState,
  type MRT_GroupingState,
  type MRT_RowData,
  type MRT_SortingState,
  type MRT_TableState,
  type MRT_VisibilityState,
} from "material-react-table";

import { R12 } from "@config/constants";
import { APP_BAR_HEIGHT, BOTTOM_ACTION_BAR_HEIGHT } from "@config/ui_constants";
import { logger as baseLogger } from "@core/logger";

import ColumnManager from "../ColumnManager/ColumnManager";
import UtfTable from "../Table/UtfTable";
import type { UtfTableProps } from "../Table/utils";
import { MIN_TABLE_HEIGHT } from "../Table/utils";
import DefaultCell from "./Cell";
import { MIN_COLUMN_WIDTH, type InfoBlockColumn, type MonthOption, type YearOption } from "./const";
import ExportButton from "./ExportFile";
import DefaultHeader from "./Header";
import { getFilteredFlatColumns, mapColumnWithDefaults, replaceCoreId } from "./utils";

const logger = baseLogger.getSubLogger({ name: "InfoBlockGrid" });

const localeKey = "en-gb";

export type InfoBlockGridProps = {
  state?: MRT_TableState<MRT_RowData>;
  columns: InfoBlockColumn[];
  data: MRT_RowData[];
  sortBy?: MRT_SortingState;
  columnVisibility?: MRT_VisibilityState;
  columnOrder?: MRT_ColumnOrderState;
  grouping?: MRT_GroupingState;
  showColumnManager?: boolean;
  onColumnManagerClose?: () => void;
  onColumnsUpdated?: (columns: {
    columnOrder?: MRT_ColumnOrderState;
    columnVisibility?: MRT_VisibilityState;
  }) => void;
  year?: YearOption;
  month?: MonthOption;
  stickyHeader?: boolean;
  footerActions?: React.ReactNode;
  loading?: boolean;
  exportFileName?: string;
  enableAggregation?: boolean;
  topOffset?: number;
} & UtfTableProps<MRT_RowData>;

const gaps =
  APP_BAR_HEIGHT +
  BOTTOM_ACTION_BAR_HEIGHT +
  // + margins
  60;

const InfoBlockGridPure: React.FC<InfoBlockGridProps> = ({
  columns: userColumns,
  data: userData,
  state = {},
  sortBy = [{ id: "id", desc: true }],
  grouping: userGrouping = [],
  columnVisibility: userColumnVisibility = {},
  columnOrder: userColumnOrder,
  showColumnManager = false,
  onColumnManagerClose,
  onColumnsUpdated,
  year,
  month,
  stickyHeader = false,
  footerActions = null,
  loading: isLoading = false,
  exportFileName,
  enableAggregation = false,
  topOffset = 0,
  ...utfTableProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showColMan, setShowColMan] = useState<boolean>(false);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(userGrouping);
  const [sorting, setSorting] = useState<MRT_SortingState>(sortBy);
  const isRollingYear = year === R12;

  // Flatten observables in user provided props
  const data = useMemo(() => {
    let rawData = userData;
    // check first row before iterating through all rows for performance
    if (isObservable(rawData[0])) {
      rawData = rawData.map((row) => toJS(row));
    }
    return rawData as MRT_RowData;
  }, [userData]);
  const rowCount = data.length;
  // Modify user provided column definitions for;
  // - Translating related fields with user defined translation options
  // - Setting the column alignment based on its type ("numbers" always aligned to the right)
  // - Chosing which "filter variant" to be used for column types
  const columns = useMemo(
    () =>
      userColumns.map(
        (column) => mapColumnWithDefaults(column, enableAggregation)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as MRT_ColumnDef<MRT_RowData, any>[],
    [enableAggregation, userColumns]
  );

  // Get table state with adjusted column IDs based on isRollingYear.
  // This handles converting column IDs between "core" and "core_rolling" prefixes.
  const tableState = useMemo(() => {
    // Get the column ID updated for the current isRollingYear value.
    const getUpdatedId = (id: string) => replaceCoreId(id, isRollingYear);

    const modifiedState: Partial<MRT_TableState<MRT_RowData>> = {
      isLoading,
      ...state,

      columnVisibility: Object.entries(columnVisibility).reduce(
        (vis: Record<string, boolean>, [id, isVisible]: [string, boolean]) => {
          vis[getUpdatedId(id)] = isVisible;
          return vis;
        },
        {}
      ),

      sorting: sorting.map(({ id, desc }) => ({
        id: getUpdatedId(id),
        desc,
      })),

      grouping: grouping.map(getUpdatedId),
    };

    if (columnOrder.length > 0) modifiedState.columnOrder = columnOrder.map(getUpdatedId);

    return modifiedState;
  }, [columnOrder, columnVisibility, grouping, isLoading, isRollingYear, sorting, state]);

  // Update column statuses from the changes coming from the column manager
  const handleOnColumnsUpdated = useCallback(
    (updatedColumns: {
      columnOrder?: MRT_ColumnOrderState;
      columnVisibility?: MRT_VisibilityState;
    }) => {
      if (onColumnManagerClose) onColumnManagerClose();

      if (!updatedColumns) return;

      const { columnOrder: nextColumnOrder, columnVisibility: nextColumnVisibility } =
        updatedColumns;

      if (Array.isArray(nextColumnOrder)) {
        setColumnOrder(nextColumnOrder);
      }

      if (nextColumnVisibility) {
        setColumnVisibility(nextColumnVisibility);
      }

      if (onColumnsUpdated) onColumnsUpdated(updatedColumns);
    },
    [onColumnManagerClose, onColumnsUpdated, setColumnOrder]
  );

  // Hide invisible columns on mount
  useEffect(() => {
    const hiddenColumnIds = getFilteredFlatColumns(userColumns, ({ isVisible }) => !isVisible);
    logger.debug(
      "INITIAL RENDER: Hiding %s columns with `isVisible=false` defined",
      hiddenColumnIds.length
    );

    setColumnVisibility((visibility: MRT_VisibilityState) => ({
      ...visibility,
      ...hiddenColumnIds.reduce((acc: MRT_VisibilityState, id?: string) => {
        if (id) acc[id] = false;
        return acc;
      }, {}),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide disabled columns
  useEffect(() => {
    const disabledColumnIds = getFilteredFlatColumns(userColumns, ({ disabled }) => disabled);
    logger.debug("Hiding %s columns with `disabled=true` defined", disabledColumnIds.length);
    setColumnVisibility((visibility: MRT_VisibilityState) => ({
      ...visibility,
      ...disabledColumnIds.reduce((acc: MRT_VisibilityState, id?: string) => {
        if (id) acc[id] = false;
        return acc;
      }, {}),
    }));
  }, [setColumnVisibility, userColumns]);

  // Toggle column manager visibility
  useEffect(() => {
    setShowColMan(Boolean(showColumnManager));
  }, [showColumnManager]);

  const heightOffset = useMemo(() => {
    if (!data.length || !columns) return;
    const tableContainer = containerRef.current;
    const distanceToTop = !tableContainer ? gaps : tableContainer.getBoundingClientRect().top;
    return Math.max(distanceToTop + gaps + topOffset, MIN_TABLE_HEIGHT);
  }, [data, columns, topOffset]);

  logger.debug("[RENDER] %j", {
    isLoading,
    sortBy,
    rowCount,
    heightOffset,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localeKey}>
      <div ref={containerRef}>
        <UtfTable
          layoutMode="grid"
          defaultColumn={{
            // @ts-expect-error TO-BE-IDENTIFIED
            Cell: DefaultCell,
            // @ts-expect-error TO-BE-IDENTIFIED
            Header: DefaultHeader,
            size: MIN_COLUMN_WIDTH,
            minSize: MIN_COLUMN_WIDTH,
          }}
          // @ts-expect-error TO-BE-IDENTIFIED
          columns={columns}
          data={data}
          rowCount={rowCount}
          initialState={{
            sorting: sortBy,
            columnVisibility: userColumnVisibility,
            columnOrder: userColumnOrder,
            grouping: userGrouping,
          }}
          state={tableState}
          sortDescFirst
          enableColumnActions
          enableColumnResizing
          enableColumnFilters
          enableColumnFilterModes
          enableFacetedValues
          enableStickyHeader={stickyHeader}
          onSortingChange={setSorting}
          onColumnOrderChange={setColumnOrder}
          onColumnVisibilityChange={setColumnVisibility}
          onGroupingChange={setGrouping}
          positionToolbarAlertBanner="none"
          columnFilterDisplayMode="popover"
          // renderers
          // @ts-expect-error TO-BE-IDENTIFIED
          renderBottomToolbarCustomActions={footerActions}
          renderToolbarInternalActions={({ table }) => (
            <Box sx={{ alignItems: "center", display: "flex", zIndex: 3 }}>
              {/* Density Toggler */}
              <ToggleDensePaddingButton table={table} color="primary" />
              {/* Fullscreen Toggler */}
              <ToggleFullScreenButton table={table} />
              {/* CSV & XLSX exporter */}
              {/* @ts-expect-error TO-BE-REFACTORED */}
              <ExportButton table={table} filename={exportFileName} disabled={isLoading} />
            </Box>
          )}
          // styles
          muiTablePaperProps={{
            sx: {
              "&&": {
                borderColor: "white",
              },
            },
          }}
          muiTableContainerProps={{
            // @ts-expect-error TO-BE-IDENTIFIED
            "data-testid": "utf-infoblock-grid",
            sx: {
              minHeight: MIN_TABLE_HEIGHT,
              maxHeight: `calc(100vh - ${heightOffset}px)`,
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              pt: 2,
              // Highlight the header group/parent
              '&:not([colspan="1"])': {
                borderBottom: "1px solid #bdbdbd",
              },
            },
          }}
          muiTopToolbarProps={{
            sx: {
              backgroundColor: "#fff",
              boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
            },
          }}
          {...utfTableProps}
        />

        {/* Column Manager UI for Adding/Removing/Sorting datatable columns */}
        {showColMan && (
          <ColumnManager
            columnOrder={columnOrder}
            columnVisibility={columnVisibility}
            onColumnsUpdated={handleOnColumnsUpdated}
            // @ts-expect-error TO-BE-IDENTIFIED
            columns={columns}
            year={year}
            month={month}
          />
        )}
      </div>
    </LocalizationProvider>
  );
};

// <InfoBlockGrid />
export const InfoBlockGrid: FC<InfoBlockGridProps> = observer(InfoBlockGridPure);

InfoBlockGrid.displayName = "InfoBlockGrid";

// for testing purpose only
export default InfoBlockGridPure;
