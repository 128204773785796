import { COLUMN_SECTION } from "./constants";

const LATEST_DATA_UPLOAD_COLUMNS = {
  processed_time: {
    label: "text_latest_data_upload",
    info: "text_latest_data_upload_info",
    section: COLUMN_SECTION.customer,
    type: "date",
  },
};

export default LATEST_DATA_UPLOAD_COLUMNS;
