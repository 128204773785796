import React, { useCallback } from "react";
import { TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Box, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

import { AltCostCollection } from "@customTypes/production/sales";
import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { NetworkStore } from "@stores/networks";
import { useInject, useLocalStorage } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";

import UtfFileReader from "../../../../../../libs/components/src/ui/inputs/UtfFileReader/UtfFileReader";

export type AltCostTradePriceCardProps = {
  data: {
    values: number[];
    name: string;
    value: number;
  };
  onChange: (values: number[], name: string, value: number) => void;
};

const ElectricityTradePriceCard = ({ data, onChange }: AltCostTradePriceCardProps) => {
  const { t } = useTranslation(["sales"]);
  const theme = useTheme();
  const { networks }: { networks: NetworkStore } = useInject("networks");
  const currency = toJS(networks.currency);

  const collectionId = alternativeCostStore.get.selectedCollection()?.id;
  const configId = alternativeCostStore.get.selectedConfiguration()?.id;
  const storageKey = `alt-cost/meta-data/${collectionId}/${configId}/electricity`;

  const [storedData, setStoredData] = useLocalStorage(storageKey, {
    name: data.name || "",
    values: data.values || [],
    value: data.value || 0,
  });

  const { control: formControl } = useForm<AltCostCollection>({
    defaultValues: {
      electricityPrice: storedData.values,
      electricityPriceDescription: storedData.name,
      electricityPriceAddon: storedData.value,
    },
  });

  const handleDataRead = useCallback(
    (values: number[], name: string) => {
      const newData = { ...storedData, name, values };
      setStoredData(newData);
      onChange(values, name, storedData.value);
    },
    [storedData, setStoredData, onChange]
  );

  const handleAddonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (!isNaN(newValue) && newValue !== storedData.value) {
        const newData = { ...storedData, value: newValue };
        setStoredData(newData);
        onChange(storedData.values, storedData.name, newValue);
      }
    },
    [storedData, setStoredData, onChange]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
      <UtfFileReader
        key={storedData.name}
        onDataRead={handleDataRead}
        initialFileName={storedData.name}
        initialData={storedData.values}
        accept=".csv"
      />
      <Box component="form" sx={{ width: "100%" }} noValidate autoComplete="off">
        <Stack spacing={1} display="flex" p={2} alignItems="center" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <TextFieldElement
              name="electricityPriceAddon"
              label={t("text_electricity_price_add_on")}
              control={formControl}
              value={storedData.value}
              size="small"
              fullWidth
              type="number"
              variant="standard"
              onChange={handleAddonChange}
              InputProps={{
                endAdornment: <Typography>{currency}</Typography>,
              }}
            />
            <FontAwesomeSvgIcon
              icon={faQuestionCircle}
              sx={{ color: theme.palette.primary.main, fontSize: 14 }}
              data-testid="help-icon"
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ElectricityTradePriceCard;