import {
  OptComponent,
  OptComponentType,
  OptConfig,
  OptJobStartParams,
  OptJobStatus,
  OptNetworkSettings,
  OptSimulationResult,
} from "@customTypes/production/optimization";
import { AltCostCollection } from "@customTypes/production/sales";

import { createAxiosClient } from "@core/apis/apiClient";
import { BaseApi } from "@core/apis/baseApi";
import { rootStore } from "@stores/root_store";

export default class OptimizationApi extends BaseApi {
  parent: typeof rootStore;
  constructor(parent: typeof rootStore) {
    super(createAxiosClient(import.meta.env.REACT_APP_OPT_URL, true));
    this.parent = parent;
    (async () => {
      const authToken = await this.parent.session.getToken();
      this.client.defaults.headers.Authorization = `Bearer ${authToken}`;
    })();
  }

  // =================================================================================================
  // Generic/Shared Endpoints
  // =================================================================================================

  async getNetworkSettings(networkId: string): Promise<OptNetworkSettings> {
    return this.client.get(`/api/networks/${networkId}/settings`).then((res) => res.data);
  }

  /**
   * Start a simulation
   * post /jobs/optimization
   *
   * @param options
   * @returns {number} jobId
   */
  async startSimulation(options: OptJobStartParams): Promise<number> {
    return this.client
      .post(`/jobs/optimization`, {
        name: "optimization",
        params: options,
      })
      .then((res) => res.data.jobId);
  }

  /**
   * Get the status of a simulation
   * get /jobs/optimization/{job_id}
   *
   * @param jobId
   * @returns {string} status
   */
  async getSimulationStatus(jobId: number): Promise<OptJobStatus> {
    return this.client.get(`/jobs/optimization/${jobId}`).then((res) => {
      if (res.data.status === "error") {
        throw new Error(res.data.error || "Unknown error");
      }
      return res.data.status;
    });
  }

  /**
   * Get the results of a simulation
   * get /jobs/optimization/{job_id}/result
   *
   * @param jobId
   * @returns {OptSimulationResult} results
   */
  async getSimulationResults(jobId: number): Promise<OptSimulationResult> {
    return this.client.get(`/jobs/optimization/${jobId}/result`).then((res) => res.data);
  }

  // TODO: In the future, these have to inherit from like a BaseOptimizationApi class
  // =================================================================================================
  // Optimization
  // =================================================================================================

  async getComponentTypes(networkId: string): Promise<OptComponentType[]> {
    return this.client
      .get(`/optimization/config/network/${networkId}/component-types`)
      .then((res) => res.data);
  }

  // =================================================================================================
  // Scenario Analysis (and some Alternative Cost)
  // =================================================================================================

  /**
   * Get the component types that are valid for scenario analysis
   * get /config/components/types/scenario
   *
   * @param networkId
   * @returns {OptComponentType[]} componentTypes
   */
  async getScenarioComponentTypes(networkId: string): Promise<OptComponentType[]> {
    return this.client.get(`/scenario-analysis/config/components/types`).then((res) => res.data);
  }

  /**
   * Upsert a component on a configuration
   * post /config/network/{network_id}/configuration/{config_id}/component
   *
   * @param networkId
   * @param configId
   * @param component
   * @returns {OptComponent} component
   */
  async createComponent(
    networkId: string,
    configId: number,
    component: OptComponent
  ): Promise<OptComponent> {
    return this.client
      .post(
        `/scenario-analysis/config/network/${networkId}/configuration/${configId}/component`,
        component
      )
      .then((res) => res.data);
  }

  /**
   * Update a component on a configuration
   * put /config/network/{network_id}/configuration/{config_id}/component/{component_id}
   *
   * @param networkId
   * @param configId
   * @param componentId
   * @param component
   * @returns {OptComponent} component
   */
  async updateComponent(
    networkId: string,
    configId: number,
    componentId: number,
    component: OptComponent
  ): Promise<OptComponent> {
    return this.client
      .patch(
        `/scenario-analysis/config/network/${networkId}/configuration/${configId}/component/${componentId}`,
        component
      )
      .then((res) => res.data);
  }

  /**
   * Update a collection
   * patch /config/network/{network_id}/collection/{collection_id}
   *
   * @param networkId
   * @param collectionId
   * @param collection
   * @returns {AltCostCollection} updated collection
   */
  async updateCollection(
    networkId: string,
    collectionId: number,
    collection: Partial<AltCostCollection>
  ): Promise<AltCostCollection> {
    return this.client
      .patch(
        `/scenario-analysis/config/network/${networkId}/collection/${collectionId}`,
        // We need the weatherData and electricityData to be strings
        // because the API expects them to be strings
        {
          ...collection,
          name: collection.name || "",
          weatherData: collection.weatherData?.join(",") || "",
          electricityPrice: collection.electricityPrice?.join(",") || "",
        }
      )
      .then((res) => res.data);
  }

  /**
   * Get the components for a configuration
   * get /config/network/{network_id}/configuration/{config_id}/components
   *
   * @param networkId
   * @param configId
   * @returns {OptComponent[]} components
   */
  async getConfigComponents(networkId: string, configId: number): Promise<OptComponent[]> {
    return this.client
      .get(`/scenario-analysis/config/network/${networkId}/configuration/${configId}/components`)
      .then((res) => res.data);
  }

  // =================================================================================================
  // Alternative Cost
  // =================================================================================================

  /**
   * Get all the collections for the network
   * get /config/network/{network_id}/collections
   *
   * @param networkId
   */
  async getCollections(networkId: string): Promise<AltCostCollection[]> {
    return this.client
      .get(`/scenario-analysis/config/network/${networkId}/collections`)
      .then((res) => {
        // Parse the weatherData and electricityPrice as arrays
        return res.data.map((collection: any) => {
          return {
            ...collection,
            weatherData: Array.isArray(collection.weatherData)
              ? collection.weatherData
              : collection.weatherData?.split(",") || [],
            electricityPrice: Array.isArray(collection.electricityPrice)
              ? collection.electricityPrice
              : collection.electricityPrice?.split(",") || [],
          };
        });
      });
  }

  /**
   * Create a new collection for the network
   * post /config/network/{network_id}/collection
   *
   * @param networkId
   * @param name
   */
  async createCollection(networkId: string, name: string): Promise<AltCostCollection> {
    return this.client
      .post(`/scenario-analysis/config/network/${networkId}/collection`, { name })
      .then((res) => res.data);
  }

  /**
   * Delete a collection
   * delete /config/network/{network_id}/collection/{collection_id}
   *
   * @param networkId
   * @param collectionId
   */
  async deleteCollection(networkId: string, collectionId: number): Promise<void> {
    return this.client
      .delete(`/scenario-analysis/config/network/${networkId}/collection/${collectionId}`)
      .then((res) => res.data);
  }

  /**
   * Get configurations for a collection
   * get /config/network/{network_id}/collection/{collection_id}/configurations
   *
   * @param networkId
   * @param collectionId
   * @returns {OptConfig[]} configurations
   */
  async getConfigsForCollection(networkId: string, collectionId: number): Promise<OptConfig[]> {
    return this.client
      .get(
        `/scenario-analysis/config/network/${networkId}/collection/${collectionId}/configurations`
      )
      .then((res) => res.data);
  }

  /**
   * Create a new configuration in a collection
   * post /config/network/{network_id}/collection/{collection_id}/configuration
   *
   * @param networkId
   * @param collectionId
   */
  async createAltCostConfiguration(networkId: string, collectionId: number): Promise<OptConfig> {
    return this.client
      .post(
        `/scenario-analysis/config/network/${networkId}/collection/${collectionId}/configuration`
      )
      .then((res) => res.data);
  }

  /**
   * Get the component types that are valid for alternative cost
   * get /config/components/types/alt-cost
   *
   * @param networkId
   * @returns {OptComponentType[]} componentTypes
   */
  async getAltCostComponentTypes(): Promise<OptComponentType[]> {
    return this.client
      .get(`/scenario-analysis/config/components/types/alt-cost`)
      .then((res) => res.data);
  }
}
