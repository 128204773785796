import { numberSort } from "@core/utils/columns/utils";

import { UNIT } from "../blocks";
import { AVERAGE_PEAK_1H_COLUMNS, AVERAGE_PEAK_24H_COLUMNS } from "./AveragePeak.columns";
import { CUSTOMER_COLUMNS, DISTRIBUTION_COLUMNS, INSTALL_ADDRESS_COLUMNS } from "./Base.columns";
import BUILDING_COLUMNS from "./Building.columns";
import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";
import CORE_COLUMNS from "./Core.columns";
import { DESIGN_LOAD_COLUMNS_1H, DESIGN_LOAD_COLUMNS_24H } from "./DesignLoad.columns";
import { ESIG_CHANGEPOINT_HEAT_ENERGY, ESIG_CHANGEPOINT_VOLUME } from "./EnergySignature.columns";
import EP_CORE_COLUMNS from "./EPCore.columns";
import HOTWATER_COLUMNS from "./HotwaterUse.columns";
import INSTALLATION_COLUMNS from "./Installation.columns";
import LATEST_DATA_UPLOAD_COLUMNS from "./LatestDataUpload.columns";
import MEASURED_PEAK_POWER_COLUMNS from "./MeasuredPeakPower.columns";
import PRICING_COLUMNS from "./Pricing.columns";
import {
  SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  SYSTEM_DESIGN_LOAD_COLUMNS_24H,
} from "./SystemDesignLoad.columns";

/**
 * Meta information of substation block columns
 */
export const COLUMN_META = {
  // -- CORE ROLLING YEARLY
  core: CORE_COLUMNS,
  core_rolling_yearly: CORE_COLUMNS,
  // EP Design Columns
  ep_design_1h: DESIGN_LOAD_COLUMNS_1H,
  ep_design_24h: DESIGN_LOAD_COLUMNS_24H,
  ep_design_noflowlimiter_1h: DESIGN_LOAD_COLUMNS_1H,
  ep_design_nopowerlimiter_1h: DESIGN_LOAD_COLUMNS_1H,
  // EP System Design Columns
  ep_systemdesign_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  ep_systemdesign_24h: SYSTEM_DESIGN_LOAD_COLUMNS_24H,
  ep_systemdesign_noflowlimiter_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  ep_systemdesign_nopowerlimiter_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  // Energy Signature Changepoint Heat Energy
  esig_changepoint_heat_energy: ESIG_CHANGEPOINT_HEAT_ENERGY,
  // Energy Signature Changepoint Volume or Flow Energy
  esig_changepoint_volume: ESIG_CHANGEPOINT_VOLUME,
  // --- EPCORE_NORMALIZED
  epcore_normalized: EP_CORE_COLUMNS,
  // --- BUILDING
  building: BUILDING_COLUMNS,
  // --- DISTRIBUTION
  distribution: DISTRIBUTION_COLUMNS,
  // --- CUSTOMER
  customer: CUSTOMER_COLUMNS,
  // --- INSTALL ADDRESS
  install_address: INSTALL_ADDRESS_COLUMNS,
  // --- PRICING
  pricing: PRICING_COLUMNS,
  // --- PEAK POWER
  measured_peak_power: MEASURED_PEAK_POWER_COLUMNS,
  // --- AVERAGE PEAK
  avg_peak_1h: AVERAGE_PEAK_1H_COLUMNS,
  avg_peak_24h: AVERAGE_PEAK_24H_COLUMNS,
  // --- INSTALLATION
  installation: INSTALLATION_COLUMNS,
  // --- HOT WATER USE
  hotwater_use: HOTWATER_COLUMNS,
  // --- LATEST DATA UPLOAD
  ufint_latest_upload: LATEST_DATA_UPLOAD_COLUMNS,
  // --- COMPUTED
  // (Columns with custom calculations based on other columns)
  computed: {
    deltatemp_flowweighted_avg: {
      output: (row: Record<string, any>) => {
        let blockName = "core";
        if (!Object.prototype.hasOwnProperty.call(row, "core")) {
          blockName = "core_rolling_yearly";
        }

        if (
          row[blockName]?.supplytemp_flowweighted_avg &&
          row[blockName]?.returntemp_flowweighted_avg
        ) {
          return (
            row[blockName].supplytemp_flowweighted_avg - row[blockName].returntemp_flowweighted_avg
          );
        }
        return null;
      },
      label: "text_delta_temperature_c",
      sublabel: "sub_text_delta_temp_c_flow_weighted",
      info: "sub_text_delta_temp_c_flow_weighted_desc",
      type: "number",
      sortType: numberSort,
      align: "right",
      spec: {
        unit: UNIT.temp,
      },
      section: COLUMN_SECTION.temperatures,
    },
    deltatemp_flowweighted_avg_normal: {
      output: (row: Record<string, any>) => {
        if (
          row["epcore_normalized"]?.supplytemp_flowweighted_avg &&
          row["epcore_normalized"]?.returntemp_flowweighted_avg
        ) {
          return (
            row["epcore_normalized"].supplytemp_flowweighted_avg -
            row["epcore_normalized"].returntemp_flowweighted_avg
          );
        }
        return null;
      },
      label: "text_delta_temperature_c",
      sublabel: "sub_text_delta_temp_c_normal_year",
      info: "sub_text_delta_temp_c_normal_year_desc",
      type: "number",
      sortType: numberSort,
      align: "right",
      spec: {
        unit: UNIT.temp,
      },
      section: COLUMN_SECTION.temperatures,
      periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
    },
    full_load_hours_1h_design: {
      output: (row: Record<string, any>) => {
        if (row["epcore_normalized"]?.heat_energy_sum && row["ep_design_1h"]?.heat_energy) {
          return row["epcore_normalized"].heat_energy_sum / row["ep_design_1h"].heat_energy;
        }
        return null;
      },
      label: "text_full_load_hour_h",
      sublabel: "sub_text_full_load_hour_h_1h",
      info: "text_full_load_hour_h_1h_desc",
      section: COLUMN_SECTION.usage_patterns,
      type: "number",
      periods: [COLUMN_PERIOD.year],
    },
    full_load_hours_24h_design: {
      output: (row: Record<string, any>) => {
        if (row["epcore_normalized"]?.heat_energy_sum && row["ep_design_24h"]?.heat_energy) {
          return row["epcore_normalized"].heat_energy_sum / row["ep_design_24h"].heat_energy;
        }
        return null;
      },
      label: "text_full_load_hour_h",
      sublabel: "sub_text_full_load_hour_h_24h",
      info: "text_full_load_hour_h_24h_desc",
      section: COLUMN_SECTION.usage_patterns,
      type: "number",
      periods: [COLUMN_PERIOD.year],
    },
  },
};
