/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { action, runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { faArrowDownToLine } from "@fortawesome/pro-light-svg-icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { FontAwesomeSvgIcon } from "../../../FontAwesomeSvgIcon";
import { UtfButton } from "../../../inputs";
import Popover from "../../../Popover";
import Radio from "../../../Radio";

const XLSXFILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const CSVFILETYPE = "text/plain;charset=UTF-8";

const handleExport = {
  asXLSXFile: (data, filename, header) => {
    const ws = XLSX.utils.json_to_sheet(data, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: XLSXFILETYPE });
    FileSaver.saveAs(dataBlob, `${filename}.xlsx`);
  },
  asCSVFile: (data, filename, downloadOption, header) => {
    const ws = XLSX.utils.json_to_sheet(data, { header });
    const range = XLSX.utils.decode_range(ws["!ref"]);
    //* loop over all of the cell
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        // eslint-disable-next-line no-continue
        if (!cell || cell.t !== "n") continue;
        //* replace the separator base on the option that the user picked
        cell.w = cell.v
          .toString(10)
          .replace(/\./, downloadOption === "CSV_WITH_DOT_SEPARATOR" ? "." : ",");
      }
    }
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: CSVFILETYPE });
    FileSaver.saveAs(blob, `${filename}.CSV`);
  },
};

function ExportButton({ table, filename, translationNs = "_common", disabled = false }) {
  const { t } = useTranslation([...translationNs, "_action"]);

  const downloadOptions = useMemo(
    () => ({
      CSV_WITH_DOT_SEPARATOR: {
        label: t("action_download_csv", { ns: "_action" }),
        info: t("action_download_csv_dot_info", { ns: "_action" }),
      },
      CSV_WITH_COMMA_SEPARATOR: {
        label: t("action_download_csv", { ns: "_action" }),
        info: t("action_download_csv_comma_info", { ns: "_action" }),
      },
      XLSX: { label: t("action_download_xlsx", { ns: "_action" }), info: "" },
    }),
    [t]
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const local = useLocalObservable(() => ({
    openPopover: null,
    downloadOption: null,

    //* Action
    handleDownloadOptionChange: action((event) => {
      local.downloadOption = event.target.value;
    }),
    togglePopoverOpen: action((event) => {
      local.openPopover = event.currentTarget;
    }),
    togglePopoverClose: action(() => {
      local.openPopover = null;
      local.downloadOption = null;
    }),
    getTableRowsAndHeaders: action(() => {
      const data = {
        // Exclude the "Expand" column
        headers: table.getVisibleLeafColumns().filter(({ id }) => id !== "mrt-row-expand"),
        rows: table.getPrePaginationRowModel().rows,
      };
      const headers = {};
      let rows = [];
      // Both "headers" and "rows" are populated by InfoBlockGrid
      // they are already sorted and filtered
      if (data?.headers || (data?.headers && data?.rows?.length > 0)) {
        // prepare column headers
        data.headers.forEach((head) => {
          const { id, parent, columnDef } = head;
          const { header, sublabel } = columnDef;
          const pHeader = parent ? t(parent.columnDef.header, { ns: translationNs }) : "";
          const label = t(header, { ns: translationNs });
          const subLabel = t(sublabel, { ns: translationNs });
          const HeaderWithSubHeader = `${label} - ${subLabel}`;
          const HeaderWithParentHeader = parent ? `${pHeader} - ${label}` : label;
          headers[id] = subLabel ? HeaderWithSubHeader : HeaderWithParentHeader;
        });

        // convert "id" based row object into "label" based one;
        // {"id": "open_0001",...} becomes -> {"Substation ID": "open_0001",...}
        rows = data.rows
          .map((rowItem) => {
            let row = null;
            if (rowItem) {
              row = {};
              Object.keys(headers).forEach((key) => {
                const colId = headers[key];
                const value = rowItem.getValue(key);

                let newValue;
                if (typeof value === "object") {
                  if (!value) {
                    newValue = null;
                    return;
                  }
                  if (Array.isArray(value)) {
                    newValue = value.join(", "); // for coord
                  } else {
                    newValue = value;
                  }
                } else {
                  newValue = value;
                }

                if (key === "ufint_latest_upload.processed_time" && value?.toFormat) {
                  newValue = value.toFormat("dd MMM yyyy HH:mm");
                }

                if (colId) {
                  if (newValue?.hasOwnProperty("props")) {
                    if (newValue.props.hasOwnProperty("title")) {
                      row[colId] = "📝"; // FD Note col temporary emoji
                    } else {
                      row[colId] = "⚑"; // FD Flag col
                    }
                  } else {
                    row[colId] = newValue;
                  }
                }
                return row;
              });
            }
            return row;
          })
          .filter(Boolean);
      }

      // ["Substation ID",...]
      const headerLabels = data?.headers.map(({ id }) => headers[id]);

      return { rows, headerLabels };
    }),
  }));

  // if we do the handleExportFile in useLocalObservable the "filename" props will not update
  const handleExportFile = () => {
    runInAction(() => {
      const { rows, headerLabels } = local.getTableRowsAndHeaders();
      if (local.downloadOption === "XLSX") {
        handleExport.asXLSXFile(rows, filename, headerLabels);
      } else {
        handleExport.asCSVFile(rows, filename, local.downloadOption, headerLabels);
      }
      local.togglePopoverClose();
    });
  };

  return (
    <>
      <Tooltip arrow title={t("text_export_list")}>
        <IconButton
          color={local.openPopover ? "secondary" : "primary"}
          onClick={!disabled && local.togglePopoverOpen}
          data-testid="export-list-button"
          size="large"
        >
          <FontAwesomeSvgIcon icon={faArrowDownToLine} />
        </IconButton>
      </Tooltip>
      <Popover open={local.openPopover} handleClose={local.togglePopoverClose}>
        <div>
          <Box
            component={Typography}
            variant="h5"
            padding={3}
            boxShadow={1}
            textTransform="uppercase"
          >
            {t("text_available_exports")}
          </Box>
          <FormControl fullWidth>
            <RadioGroup value={local.downloadOption} onChange={local.handleDownloadOptionChange}>
              {Object.entries(downloadOptions).map(([optionId, option]) => (
                <Fragment key={optionId}>
                  <FormControlLabel
                    value={optionId}
                    data-testid={optionId}
                    control={
                      <Radio
                        sx={{
                          marginRight: 1,
                        }}
                      />
                    }
                    label={
                      <>
                        <Typography variant="caption">{option.label}</Typography>
                        <Typography fontSize={12}>{option.info}</Typography>
                      </>
                    }
                    sx={{
                      margin: 0,
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      ".MuiFormControlLabel-label": {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        mr: 5,
                        p: 2,
                        pl: 3,
                      },
                    }}
                  />
                  <Divider />
                </Fragment>
              ))}
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: [2, 3],
            }}
          >
            <UtfButton
              color="primary"
              variant="contained"
              disabled={local.downloadOption === null}
              onClick={handleExportFile}
              data-testid="confirm-export-button"
            >
              {t("action_confirm", { ns: "_action" })}
            </UtfButton>
            <UtfButton
              color="primary"
              variant="outlined"
              onClick={local.togglePopoverClose}
              data-testid="cancel-export-button"
            >
              {t("action_cancel", { ns: "_action" })}
            </UtfButton>
          </Box>
        </div>
      </Popover>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default observer(ExportButton);
